@import "#/mixins.scss";

.group-info-header {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.group-exp-date {
  font-size: 0.8rem;
  color: grey;
}

.query-list-item {
  height: 35px;
}

.queries-group-list {
  $active-item-color: #009688;
  $hover-item-color: #e7eaeb;

  max-height: 650px;
  border-bottom: 1px solid #cfcfcf;
  border-top: 1px solid #cfcfcf;
  border-radius: 4px;
  overflow-y: scroll;

  span.badge {
    font-weight: 400;
  }

  .sidebar-group-item {
    font-size: 0.8rem;
    padding: 8px;
    cursor: pointer;

    &:first-child {
      border-top: none;
    }

    &:last-child {
      border-bottom: none;
    }

    &:global(.disabled) {
      cursor: default;
      .rc-switch {
        opacity: 0.5;
        &.rc-switch-checked {
          opacity: 1;
        }
      }
    }

    &:not(:global(.disabled)):not(:global(.active)):hover {
      background-color: $hover-item-color;
      border-color: $hover-item-color;
    }

    :global(.list-group-item-action) {
      display: inline-block;
      width: unset;
      border: none;

      &:hover {
        background: transparent;
      }
    }

    &:global(focus) {
      outline: none;
    }

    &:global(.active) {
      border-color: $active-item-color;
      background-color: $active-item-color;
    }
  }
}

.actions-list {
  @include display-flex-row;
  flex: 1 1 100%;
  justify-content: flex-end;

  .configuration-select {
    flex: 0 0 250px;
  }
}
