@import "#/mixins.scss";

.request-code-wait-timer-window {
  font-size: 0.8rem;
  @include display-flex-row;
  @include flex-full-align;

  button {
    font-size: inherit;
    padding: 0;
  }
}

.form-bottom {
  @include display-flex-row;
  justify-content: space-between;
}
