@import "#/mixins.scss";

.notification-menu-item {
  @include display-flex-row;
  min-height: var(--notification-menu-item-min-height);
  cursor: pointer;
  padding: 10px 20px;
  margin-bottom: 0;

  &__body {
    @include display-flex-row;
    height: 100%;
  }

  &__left-side {
    @include display-flex-row;
  }

  &__date {
    font-size: 0.6rem;
    color: grey;
    @include display-flex-column;
    justify-content: flex-end;
    padding-left: 12px;
  }

  &__inner-wrapper {
    @include display-flex-row;
    width: 100%;
    flex: 1 1 100%;
    height: 100%;
    justify-content: space-between;
    align-items: stretch;
  }

  &__status-circle-container {
    @include display-flex-row;
    align-items: center;
    height: inherit;
    flex: 0 0 auto;
    margin-right: 20px;
  }

  &__status-circle {
    @include filled-circle(8px, 50%, rgba(27, 17, 173, 0.692));
    cursor: pointer;
    @include display-flex-column;
    @include flex-full-align;
  }

  &__text {
    height: inherit;
    @include display-flex-row;
    align-items: center;
    flex: 1 1 100%;
    white-space: normal;
  }
}
