@import "#/mixins.scss";

.topvisor-container {
  background: #fff;
  width: 100%;
  height: 100%;

  .monitoring-project-title {
    background: #fff;
    color: #0b002a;
    border-bottom: 1px solid #e2e2e2;
    padding-left: 30px;
    padding-right: 30px;

    button {
      color: #434343;
    }
  }

  .sidebar {
    background: #ecf0f1;
    z-index: 100;
    padding-right: 1px;
    min-width: 250px;

    @media screen and (max-width: 992px) {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .buttons-control {
      margin: 13px 3px 10px 13px;
    }

    .sidebar-inner {
      padding: 10px 0 0 0;
    }
  }

  .topvisor-content {
    padding: 20px 20px 0 20px;
    display: flex;
    min-height: 800px;

    .content-inner {
      width: 100%;
      height: 100%;
    }

    .tab-content {
      height: 100%;
    }

    .tab-pane {
      height: 100%;
    }

    .content-inner-topvisor {
      @include display-flex-column;
      position: relative;
      height: 100%;

      h2 {
        font-size: 1.125rem;
        margin: 0 0 20px 0;
        font-weight: 400;
      }

      .bar.topvisor-content-main {
        @include flex(1, 1, 100%);

        .title-component-no-items-container {
          @include display-flex-column;
          @include flex-full-align;
        }
      }
    }
  }

  .topvisor-table {
    height: 100%;

    table {
      font-size: 0.85rem;

      thead tr th {
        padding: 4px 8px;
        vertical-align: middle;
      }

      tbody td {
        border: 1px solid #dee2e6;
        font-size: 0.85rem;
        vertical-align: initial;
      }

      .minimal-column {
        width: 120px;
      }

      .diff {
        word-wrap: break-all;
      }

      del {
        background: #ffe1e1;
        color: #e74c3c;
        opacity: 0.8;
        padding: 0 3px;
      }
      ins {
        background: #e1fff9;
        padding: 0 3px;
        color: #16a085;
        text-decoration: none;
      }
    }
  }
}

.title-component-loader-container {
  flex: 1 1 100%;
  @include display-flex-column;
  @include flex-full-align;
  height: 100%;
}

.url-component-control-panel {
  @include display-flex-row;
  width: 100%;
  justify-content: space-between;
}
