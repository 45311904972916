@import "#/mixins.scss";

.checklist {
  @include display-flex-column;
}

.checklist-items-container {
  @include display-flex-column;
  width: 100%;
  max-height: 450px;
  overflow: auto;

  &-big {
    min-height: 400px;
  }
}

.no-items-msg {
  @include display-flex-column;
  @include flex-full-align;
  width: 100%;
  padding: 24px;
  flex-shrink: 0;
  flex-grow: 1;
}

.items-container {
  flex: 1 1 100%;
}
