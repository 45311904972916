@import "#/mixins.scss";

table {
  tbody {
    .query-position-item-row {
      &.query-position-item_no-expiration-date {
        .query-position-item__name {
          color: var(--info) !important;
        }
      }

      td {
        font-size: 0.8rem !important;

        &.query-position-item__position {
          border-left: 1px solid #eee !important;
        }
      }
    }
  }
}

.configuration-select {
  flex-basis: 300px;
}
