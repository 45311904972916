.topf-project-list-item {
    .topf-project-list-item-hidden {
        text-align: right;  
        opacity: 0;
    }

    &:hover .topf-project-list-item-hidden {
        transition: 0.25s;
        opacity: 1;
    }
}