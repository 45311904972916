@import "#/mixins.scss";

.profiles-list-header {
  @include display-flex-row;
  justify-content: space-between;
}

.invitation-code-display {
  font-size: 0.9rem;
  height: 80px !important;
}
