.wrapper-editor {
  .html-editor {
    border-radius: 0.25rem;
    border: 1px solid #ced4da;
    min-height: 6em;
    box-sizing: border-box;
    cursor: text;
    padding: 0.375rem 0.75rem;
    color: #495057;

    &:focus {
      border-color: #7ebcd6;
      color: #495057;
    }

    .public-DraftStyleDefault-block {
      margin: 0;
    }
    .DraftEditor-editorContainer {
      border: none;
    }

    :global(.public-DraftEditor-content) {
      min-height: 6em;
    }
  }
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  height: 30px;

  &:hover {
    background: #f6f8ff;
    border-color: #f6f8ff;
    box-shadow: none;
  }
}
.rdw-option-active {
  background: #fff0f0;
  border-color: #fff0f0;
}

.rdw-link-modal-btn {
  width: unset;
}
.rdw-link-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: unset;
  height: unset;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: #fff;
  box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-link-modal-target-option {
  display: flex;
  flex-wrap: wrap;
}
.rdw-link-modal-buttonsection {
  display: flex;

  flex-direction: row-reverse;
}
.rdw-dropdown-optionwrapper {
  width: unset;
  // padding-right: 10px;
}
// .rdw-fontsize-option {
//     padding-right: 12px;
// }
