@import "#/mixins.scss";

.sections {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  padding-bottom: 1.5rem;

  .keybindings-section {
    @include display-flex-column;

    &__title {
      font-weight: normal;
      color: var(--subtext-color);
      font-variant-caps: all-small-caps;
      font-size: 1.25rem;
      padding: 1rem 0;
    }

    &__keybindings-list {
      .keybinding-item {
        font-size: 0.83rem;
        padding: 0.6rem 0;
        display: flex;
        justify-content: space-between;

        &__description {
          color: var(--subtext-color);
        }

        &__shortcut {
          color: var(--black-color);
        }
      }
    }
  }
}
