// CLEANUP: Move this file to #/styles

@mixin display-flex-column {
  display: flex;
  flex-direction: column;
}

@mixin display-flex-column-reverse {
  display: flex;
  flex-direction: column-reverse;
}

@mixin display-flex-row {
  display: flex;
  flex-direction: row;
}

@mixin display-flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

@mixin display-flex-inherit {
  display: flex;
  flex-direction: inherit;
}

@mixin flex($grow, $shrink, $basis) {
  flex: $grow $shrink $basis;
}

@mixin flex-full-align {
  justify-content: center;
  align-items: center;
}

@mixin box($a) {
  width: $a;
  height: $a;
}

@mixin circle($a, $br) {
  @include box($a);
  border-radius: $br;
}

@mixin filled-circle($a, $br, $bg) {
  @include circle($a, $br);
  background: $bg;
}

@mixin circle-with-border($a, $br, $b) {
  @include circle($a, $br);
  border: $b;
}

@mixin disable-user-select {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

@mixin disable-pointer-events {
  pointer-events: none;
}

@mixin default-cursor {
  cursor: default;
}
