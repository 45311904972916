@import "#/mixins.scss";

.navbar {
  @include display-flex-row;
  flex-wrap: nowrap;
}

.search-container {
  flex: 1 1 100%;
  @include display-flex-row;
  margin-right: var(--navbar-sides-padding);

  form {
    flex: 1 1 100%;
    .search-input {
    }
  }
}
