.table {
  thead {
    background: #f5f8ff;
    border-bottom: 2px solid #f4f4f4;

    tr {
      font-size: 0.9rem;

      th {
        font-weight: 500;
        color: #797a82;
        text-transform: uppercase;
        font-size: 0.75rem;
      }
    }
  }

  tbody {
    tr {
      transition: 0.3s;
    }

    td {
      font-size: 0.9rem;
      border: none;
      border-top: 1px solid #f4f4f4;
      font-weight: 400;
      color: #3d4465;
      transition: all 0.3s;
      text-align: left;
    }
  }

  td,
  th {
    vertical-align: middle;
  }
}

.selectable-column {
  cursor: pointer;
}

.table td.hiddenRow {
  padding: 0;
  border: 0;
  background: #fff;

  .card {
    border-radius: 4px;
  }
}

tbody tr.inactive {
  background: #dddddd !important;
  opacity: 0.4;
}

tbody tr.selected {
  background: #fffce4 !important;
  opacity: 1;
}

.row-action {
  transition: opacity 0.2s ease-in;
  padding: 3px 6px;
  margin-left: 6px;
  font-size: 0.9rem;

  &.without-margin {
    padding: 2px 5px;
    margin-left: 0;

    &:not(:first-child) {
      margin-left: 4px;
    }
  }

  &.small-opacity {
    opacity: 0.4;
  }

  &.rm {
    padding: 2px 6px;
  }
}

tr.accordion-toggle:hover .row-action,
tr:hover .row-action {
  opacity: 1;
}

.card-body-expandrow {
  background: #f9f9fc;
  color: #646c9a;
}

.btn-list {
  background: transparent;
  color: #93a2dd;
  border: 0;

  &:hover,
  &:active,
  &.active {
    color: #4d5cf2;
    background: #f0f3ff;
  }
}

.accordion-toggle select {
  font-size: 0.9rem;

  &.form-control {
    height: unset;
  }
}
