.expandrow-block {
  margin-top: 6px;

  .expandrow-title {
    font-size: 0.9rem;
    font-weight: 500;
    color: #4c4949;
  }

  .expandrow-content {
    line-height: 1.3;
    margin-top: 5px;
  }

  span.expandrow-subtitle {
    display: block;
  }
}

.task-link {
  position: absolute;
  right: 20px;
  top: 10px;

  a {
    font-size: 1.5rem;
    color: #242a33;
  }
}
