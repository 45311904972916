@import "#/mixins.scss";

.no-projects-message {
  @include display-flex-column;
  @include flex-full-align;
  min-height: 200px;
  width: 100%;
  flex: 1;
  height: 100%;
}

.top50-projects-list {
  flex: 1 1 100%;
}
