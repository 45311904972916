.tags-list {
  .tag {
    color: blue;
    margin-right: 4px;
  }
}

.suggestions-popup-container {
  position: relative;
  overflow: visible;
}

.suggestions-list {
  padding: 0;

  .suggestion-item {
    cursor: pointer;
    padding: 4px 8px;

    &:hover {
      background: var(--primary-hover-color);
    }

    &:active {
      background: var(--primary-active-color);
    }
  }
}
