@import "#/mixins.scss";

// TODO: Fix invalid behaviour when margin is added

.checkbox {
  @include disable-user-select;

  &_small {
    @include box(16px);
  }

  &_medium {
    @include box(20px);
  }

  &_large {
    @include box(24px);
  }

  &-label {
    top: 1px;
    padding: 0;
    margin: 0 0 0 0;
    width: inherit;
    height: inherit;
    display: inline-block;
    position: relative;
    text-align-last: auto;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.2s ease;

    > span {
      border-radius: 3px;
      background: none;
      position: absolute;
      top: 1px;
      left: 0;
      height: inherit;
      width: inherit;
      background-color: #f0f3ff;
      transition: 0.2s;
    }

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      padding: 0;

      &:checked ~ span {
        background: #4d5cf2;

        &::after {
          display: block;
          border: solid #fff;
        }
      }
    }

    span::after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      margin-left: -2px;
      margin-top: -6px;
      width: 5px;
      height: 10px;
      border-width: 0 2px 2px 0 !important;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      border: solid #4d5cf2;
    }
  }
}
