@import "#/mixins.scss";

.project-actions {
  @include display-flex-row;

  .project-sub-button {
    &__sub-text {
      display: block;
    }

    &__unsub-text {
      display: none;
    }

    &__subbed-text {
      display: none;
    }

    &.project-sub-button_subscribed {
      .project-sub-button__sub-text {
        display: none;
      }

      .project-sub-button__unsub-text {
        display: none;
      }

      .project-sub-button__subbed-text {
        display: block;
      }

      &:hover {
        .project-sub-button__unsub-text {
          display: block;
        }

        .project-sub-button__sub-text {
          display: none;
        }

        .project-sub-button__subbed-text {
          display: none;
        }
      }
    }
  }
}

.sidebar-tab {
  border-radius: 3px 0 0 3px;
  border: 1px solid #ecf0f1 !important;
  border-right: none;
  border-right-color: currentcolor;
  padding: 9px 10px;
  margin: 2px 0;
  color: #34495e;
  text-decoration: none;
  display: block;
  position: relative;
  right: -1px;
  transition-duration: 300ms;
  width: 100%;
  background: transparent;
  text-align: left;
  outline: none !important;
  box-shadow: none !important;

  &.sidebar-tab_active {
    border-color: #dbe5ea !important;
    background-color: #fff !important;
    z-index: 1;
    color: #00bd9c;
    outline: none !important;
  }

  &:hover,
  &:active {
    color: #00bd9c;
    background-color: #f5f9fa !important;
    border: 1px solid #ecf0f1 !important;
    outline: none !important;
  }

  &:focus,
  &:active:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
