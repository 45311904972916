@import "#/mixins.scss";

.content-wrapper {
  background-color: #f2f3f8;
  z-index: 800;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  height: 100%;
  @include display-flex-column;

  &.sidebar-active {
    margin-left: 260px;
  }
}

.select-task-type {
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    opacity: 0.6;
  }
}
