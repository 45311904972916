.goal-form {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
  &__type-buttons {
    display: flex;
    align-content: space-between;
  }
}
