@import "#/mixins.scss";

$pp: 24px;

.page-content {
  @include display-flex-row;
  padding: $pp;
}

.left-block {
  height: 100%;
  flex: 1 1 300px;

  .tabs {
    white-space: nowrap;

    .tab {
      padding: 8px 14px;
      cursor: pointer;
      color: var(--primary-color);
      font-size: 0.91rem;

      &:hover {
        background: var(--primary-hover-color);
      }

      &:active,
      &:focus,
      &_active,
      &_active:hover {
        background: var(--primary-hover-color);
        color: var(--black-color);
        font-weight: bold;
      }
    }
  }
}

.right-block {
  height: 100%;
  flex: 1 1 100%;
  margin-left: $pp;
  $hp: 16px;
  $vp: 16px;

  .tab-container {
    @include display-flex-column;
    height: 100%;

    .tab-title {
      padding-left: $hp;
      padding-right: $hp;
      padding-top: $vp;
      margin-bottom: $vp;
    }

    .tab-body {
      height: 100%;
      padding: 0 $hp;
      padding-bottom: $vp;
    }

    .tab-section {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.list-item {
  border: none;
  padding: 8px;
}
