@import "#/mixins.scss";

// CLEANUP: Move these to a separate file (or root/index.scss)
$blue: #398bf7;
$skin-blue-color-secondary: #687384;

.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 60px;
  min-height: 100%;
  width: 0px;
  z-index: 10;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -moz-transition: -moz-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  overflow: visible;
  @include display-flex-column;
  flex: 1 1 100%;
  height: 100%;

  &.active {
    width: 260px;

    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 992px) {
    position: absolute;
  }

  @media screen and (min-width: 576px) {
    padding-top: 0;
  }

  .sidebar {
    @include display-flex-inherit;
    flex: 1 1 100%;
  }

  .user-panel {
    white-space: nowrap;
    overflow: hidden;

    .ulogo {
      text-align: center;
      padding-bottom: 15px;
      font-size: 1.125rem;
      font-weight: 300;

      &:hover {
        opacity: 0.5;
      }
    }

    .image img {
      width: auto;
      height: 100px;
      margin: 0 auto;
      display: block;
      border: 1px solid rgba(120, 130, 140, 0.13);
      padding: 5px;
    }
  }
}

.skin-blue {
  .main-sidebar {
    background-color: #242a33;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  }

  .sidebar {
    border-color: $blue;

    a,
    span.treeview-link {
      color: $skin-blue-color-secondary;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .sidebar-menu {
    li.header {
      color: $skin-blue-color-secondary;
    }

    li.active {
      a.treeview-link,
      span.treeview-link {
        border-left-color: #607d8b;
        color: #ffffff;
      }
    }
  }

  .user-panel {
    .info {
      color: #fff;
      white-space: normal;
      line-height: 1.5;

      p {
        padding: 0 30px;
      }

      a {
        color: #fff;
      }
    }
  }
}

.user-panel {
  position: relative;
  width: 100%;
  padding: 15px 0 0px;

  .info {
    padding: 15px 5px 5px 5px;
    line-height: 1;
    margin: 0 auto;
    text-align: center;

    a {
      font-size: 0.9rem;
      padding: 5px 10px;
      display: inline-block;
    }
  }
}

li.treeview {
  cursor: pointer;

  &:hover span.treeview-link {
    color: #fff;
  }
}

.btn-transparent.dropdown-toggle {
  width: 100%;
  padding: 2px 0px 2px 0px;
  display: block;
  font-size: 0.85rem;
  color: #687384;
  text-align: left;
  outline: none !important;
  border: none !important;
  background: transparent !important;

  &:hover {
    color: #fff;
  }

  &:active,
  &:focus,
  &:hover {
    background: transparent !important;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.sidebar-menu {
  white-space: nowrap;
  list-style: none;
  margin: 0;
  padding: 0px 0 0 0px;
  @include display-flex-inherit;
  flex: 1 1 100%;
  justify-content: space-between;

  li {
    position: relative;
    margin: 0;
    padding: 0;

    &.list-select-item {
      padding: 5px 5px 6px 59px;
      cursor: pointer;

      select {
        background: transparent;
        border: none;
        font-size: 0.85rem;
        color: #687384;

        &:hover {
          color: #fff;
        }

        option {
          color: #222;
        }
      }
    }

    .treeview-link:hover {
      color: #fff;
    }

    .treeview-menu {
      max-height: 0;
      list-style: none;
      padding: 0;
      margin: 0 0px;
      background: #242a33;

      li {
        margin: 0;

        .treeview-link-item {
          padding: 8px 5px 8px 60px;
          display: block;
          font-size: 0.85rem;
        }

        .treeview-link-item {
          color: #687384;
          &:hover {
            color: #fff;
          }
        }
      }

      &.active {
        max-height: 1000px;
        transition: max-height 1000ms ease-in-out;
      }

      &.hidden {
        overflow: hidden;
        transition: max-height 1000ms cubic-bezier(0, 1, 0, 1);
      }
    }

    .treeview-link {
      border-left: 3px solid #242a33;
      position: relative;
      font-weight: 400;
      padding: 10px 5px 10px 10px;
      display: block;

      &.settings-item {
        width: 100%;
        font-size: 0.95rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        @include disable-user-select;
      }

      i {
        width: 40px;
        font-size: 1.125rem;
        display: inline-block;
        vertical-align: middle;
        padding-left: 0;
        margin-right: 10px;
        line-height: 40px;
        text-align: center;
        border-radius: 100px;
      }

      .pull-right-container {
        position: absolute;
        right: 10px;
        background: #242a33;

        .fa-angle-right,
        .fa-angle-down {
          width: auto;
          height: auto;
          padding: 0;
          margin-right: 10px;
          -webkit-transition: transform 0.5s ease;
          -o-transition: transform 0.5s ease;
          transition: transform 0.5s ease;
        }
      }
    }
  }

  li.nav-divider {
    height: 1px;
    background: rgba(120, 130, 140, 0.13);
    display: block;
    margin: 15px 0;
  }

  li.header {
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
    padding: 20px 25px 10px 15px;
    font-size: 0.8rem;
    font-weight: 500;
  }
}
