// .accountInfo {
//   position: absolute;
//   bottom: 0;
// }

.list-group.collapse {
  .list-group-item-action {
    transition: 0.2s;
    &:hover {
      background: #eff6ff;
    }
  }
}
