.text-break {
  overflow-wrap: break-word !important;
}

.rt-tbody {
  max-height: calc(70vh - 50px);
}

.account_table {
  display: block;
  overflow: auto;
  border-spacing: 0;
  border: 1px solid black;
  .tbody {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .thead {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tr {
    :last-child {
      .td {
        border-bottom: 0;
      }
    }
    border-bottom: 1px solid black;
  }
  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    border-right: 1px solid black;
    position: relative;
  }
}
