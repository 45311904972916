.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 100%;
}

section.page-content {
  margin-right: 0;
  margin-left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}
