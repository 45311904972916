@import "#/mixins.scss";

.user-avatar {
  @include display-flex-row;
  @include flex-full-align;

  $small-avatar-size: 40px;
  $small-avatar-br: 100%;

  $medium-avatar-size: 60px;
  $medium-avatar-br: 100%;

  $large-avatar-size: 80px;
  $large-avatar-br: 100%;

  $admin-color: var(--info-color);
  $super-admin-color: var(--dark-grey-color);

  &_sm {
    @include circle($small-avatar-size, $small-avatar-br);
    .dot-user-badge {
      @include circle(12px, 100%);
    }
  }

  &_md {
    @include circle($medium-avatar-size, $medium-avatar-br);
    .dot-user-badge {
      @include circle(18px, 100%);
    }
  }

  &_lg {
    @include circle($large-avatar-size, $medium-avatar-br);
  }

  &_user {
    .user-badge {
      display: none;
    }
    .dot-user-badge {
      display: none;
    }
  }

  &_admin {
    .user-badge {
      background: $admin-color;
    }
    .dot-user-badge {
      background: $admin-color;
    }
  }

  &_super-admin {
    .user-badge {
      background: $super-admin-color;
    }
    .dot-user-badge {
      background: $super-admin-color;
    }
  }

  &__overlay {
    position: relative;

    img {
      margin: 0;
    }

    &__upload {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 50px;
      font-size: 0.6rem;
      color: black;
      white-space: nowrap;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: default;
      padding: 4px 12px;
      font-weight: bold;
      font-size: 2rem;
    }
  }

  .user-badge {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50px;
    font-size: 0.6rem;
    color: white;
    white-space: nowrap;
    @include display-flex-row;
    @include flex-full-align;
    @include disable-user-select;
    @include default-cursor;
    padding: 4px 12px;
    font-weight: bold;
  }

  .dot-user-badge {
    position: absolute;
    $off: -6px;
    left: $off;
    bottom: $off;
    border: 4px solid #fff;
    box-sizing: content-box;
  }
}
