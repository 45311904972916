@import "#/mixins.scss";

.page-wrapper {
  flex: 1 1 100%;
  @include display-flex-column;
}

.content {
  width: 100%;
}

.task-main-box-description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__agreement {
    background: #cce5ff !important ;
    flex: 0;
  }

  &__row {
    display: flex;
    align-items: center;
  }
}

.loader-container {
  @include display-flex-column;
  flex: 1 1 100%;
  @include flex-full-align;
}

.task-main-box-header {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-start;
}

.title-group {
  @include display-flex-row;
  flex: 0 0 auto;
  align-items: center;

  &__title {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 5px;
    span {
      margin: 2px;
    }
  }
}

.edit-toggle-group {
  @include display-flex-row;
  justify-content: flex-end;
  flex: 0 0 auto;
  padding: 5px;
  button {
    margin: 5px;
  }
}

.info-block-item {
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.main-info-block-item {
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }
}

.become-executor-button {
  width: 100%;
  button {
    width: 100%;
  }
}

.info-title {
  flex: 1 0 auto;
}

.task-info-block-header {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: inherit;

  .task-notifications-sub-container {
    color: inherit;
    flex: 0 0 35px;
    display: flex;
    height: 30px;
    @include flex-full-align;

    button {
      color: inherit;
      padding: 0;
      font-size: 1.375rem;

      .subscribed-status-bell {
        display: block;
      }

      .unsubscribe-bell {
        display: none;
      }

      &:hover {
        .subscribed-status-bell {
          display: none;
        }

        .unsubscribe-bell {
          display: block;
        }
      }
    }
  }
}

.task-options-dropdown {
  @include flex-full-align;
  color: inherit;
  flex: 0 0 35px;
  display: flex;
  height: 100%;

  &__toggle {
    color: inherit;
    padding: 0;
    font-size: 1.375rem;
  }
}

.task-status-comment {
  font-size: 0.9rem;
  color: var(--subtext-color);
  padding-top: 0.5rem;
}
