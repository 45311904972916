@import "#/mixins.scss";

.box-title-container {
  @include display-flex-row;
  align-items: center;
  $p: 8px;
  span:first-of-type {
    margin-right: $p;
  }
}

.box-body {
  padding: 0.25rem 1rem;
}

.box-wrap {
  @include display-flex-row;
  align-items: center;
  padding: 0.5rem 0.25rem;

  &__icon {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 1.25rem;
    }
  }

  &__text {
    margin-left: 1.5rem;

    &__count {
      font-weight: 500;
      font-size: 1.1rem;
    }

    &__container {
      font-size: 0.8rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.85px;
      color: #949494;
    }
  }
}

.box-count {
  font-size: 2rem;
}
