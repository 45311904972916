.projectstats-metrics button {
  font-size: 0.9rem;
}

.projectstats-block {
  display: inline;
  border-right: 1px solid #ebebeb;
  padding-right: 1.5rem;
  padding-top: 12px;
  padding-bottom: 15px;
}

text.recharts-text {
  font-size: 0.8rem;
}

.topf-project-stats-queries {
  tr td,
  tr th {
    font-size: 0.85rem;
  }

  tr td {
    padding: 0.35rem 0.4rem;
  }

  tr th {
    padding: 0.5rem 0.3rem;
  }
}

.active-rating {
  background: #cbaeff;
  border-color: #cbaeff;
}

.active-dynamics {

}

.table tbody td.bordered-column-pos,
.table thead th.bordered-column-pos {
  border-right: 1px solid #e2e2e2;
}
