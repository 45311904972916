@import "#/mixins.scss";

.screen {
  @include display-flex-column;
  @include flex-full-align;
  height: 100%;
  opacity: 0;

  &_appear {
    opacity: 1;
  }

  &_exit {
    opacity: 0;
  }
}
