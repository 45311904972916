.goals {
  display: flex;
  justify-content: space-evenly;
  &__left {
    width: 50%;
    display: flex;
    flex-direction: column;
    & > * {
      padding-bottom: 10px;
    }
  }
  &__right {
    width: 40%;
  }
}
