@import "#/mixins.scss";

.item {
  background: white;
  cursor: pointer;
  color: #67757c;
  font-size: 0.85rem;
  padding: 10px 0;
  display: block;
  text-decoration: none;

  &:hover {
    background: var(--primary-hover-color);
  }

  &:focus {
    outline: none;
  }

  &:active {
    background: var(--primary-active-color);
    color: #000 !important;
    cursor: default;
  }

  i {
    width: 2.5rem;
    text-align: center;
  }
}

.user-header-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  background-color: #fff;
  padding: 0.75rem;
  min-width: 250px;

  .user-avatar {
    z-index: 5;
    border: 3px solid transparent;
    border-color: rgba(255, 255, 255, 0.2);
    @include circle(75px, 100%);
    margin-right: 20px;
  }

  .user-info {
    margin-left: auto;

    &__name {
      font-size: 0.95rem;
      margin-bottom: 0.5rem;
    }

    &__email {
      font-size: 0.8rem;
      color: var(--subtext-color);
    }

    &__balance {
      font-size: 0.8rem;
      color: #8a8695;
    }
  }
}

.user-body-section {
  padding: 0px;
  border-top: 1px solid #ddd;

  .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: rgba(120, 130, 140, 0.13);
  }
}

.dropdown-divider {
  color: var(--black-color);
}

.dropdown {
  .menu-toggle {
    padding: 0 8px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 50px;
    border-radius: 50px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #959cb6;

    @media screen and (max-width: 992px) {
      padding: 0;
    }

    &:hover {
      background: var(--navbar-button-hover-bg);
    }

    &__username {
      transition: 0.3s;
      display: flex;
      align-self: center;
      padding: 0 0.55rem 0 0;
      font-weight: 500;
      font-size: 1rem;
      color: #fff;
    }
  }

  &.show {
    .menu-toggle {
      background: var(--navbar-button-hover-bg);
    }
  }
}
