@media screen and (min-width: 991px) {
  .box-body {
    &.with-border {
      border-right: 1px solid #ebebeb;
    }
  }
}

.box-wrap {
  position: relative;
  height: 53px;

  .box-wrap-count {
    position: absolute;
    right: 0;
    top: 0;
  }
}
