@import "#/mixins.scss";

// TODO: Refactor this file into CSS module

$navbar-button-color: var(--navbar-button-hover-bg);

.main-header {
  position: relative;
  max-height: 120px;
  z-index: 1030;

  .navbar {
    -webkit-transition: margin-left 0.3s ease-in-out;
    -o-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
    margin-bottom: 0;
    border: none;
    min-height: 75px;
    border-radius: 0;
    padding: 0 var(--navbar-sides-padding);

    &.sidebar-active {
      margin-left: 260px;

      @media screen and (max-width: 992px) {
        margin-left: 0;
      }
    }
  }

  .sidebar-toggle {
    float: left;
    width: 50px;
    border-radius: 50%;
    background-color: transparent;
    background-image: none;
    font-family: fontAwesome;
    transition: 0.3s;
    font-size: 1.25rem;
    margin-right: var(--navbar-sides-padding);

    &:hover {
      background: $navbar-button-color;
    }
  }
  .navbar-custom-menu {
    float: right;
  }
}

@media screen and (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }
}

.skin-blue .main-header .navbar {
  background-color: #1376ab;

  &.sidebar-active {
    @media screen and (max-width: 992px) {
      background-color: transparent;
    }
  }

  .sidebar-toggle {
    color: #fff;
  }
}

.skin-blue .main-header .navbar .nav > li > a {
  color: #ffffff;
}

.navbar-custom-menu {
  display: flex;
  align-items: stretch;
  padding: 0;
  padding-right: 0px;
  padding-right: 15px;

  @media screen and (max-width: 992px) {
    padding-right: 0;
  }

  .dropdown-show > .dropdown-menu {
    transform-origin: right 0;
  }

  .dropdown-menu.scale-up {
    box-shadow: 0px 0px 50px 0px #523f6926;
    border-color: rgba(120, 130, 140, 0.13);
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scale(0);
    transform: scale(0);
    transform-origin: right 0;
    max-height: unset !important;
  }

  .show > .dropdown-menu.scale-up {
    transform: scale(1);
    -webkit-transform: scale(1);
    transform-origin: left 0;
    -webkit-transform-origin: left 0;
  }

  .dropdown-menu {
    border-radius: 10px;
    padding: 0;
    border-top-width: 0;
    width: fit-content;
    overflow: hidden;
    position: absolute;
    top: 40px;
  }
}

.btn-user-card {
  background: transparent;
  border: none;
  color: #67757c;
  padding: 9px 10px;
  display: block;
  color: #67757c;
  font-size: 0.8rem;
}

.navbar-custom-menu .header-navbar-item {
  display: flex;
  align-items: stretch;
  margin: 0 0.1rem;

  .header-navbar-wrapper {
    cursor: pointer;
    display: flex;
    align-items: stretch;
  }

  .header-navbar-icon {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;

    i {
      font-size: 1.5rem;
      transition: all 0.3s;
      color: #fff;
    }

    &:hover {
      transition: all 0.3s;
      background-color: $navbar-button-color;
    }
  }

  .navbar-custom-menu-welcome {
    display: flex;
    align-self: center;
    padding: 0 0.55rem 0 0;
    font-weight: 500;
    font-size: 0.9rem;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #fff;
  }
}

.k-badge {
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  font-size: 0.8rem;

  &.k-badge--lg {
    height: 30px;
    width: 30px;
    font-size: 1.25rem;
  }
  &.k-badge--brand {
    color: #fff;
    background: #0d3fa9;
  }
  &.k-badge--username {
    height: 38px;
    width: 38px;
  }
}

.navbar-custom-menu .header-navbar-item .navbar-custom-menu-wrapper {
  cursor: pointer;
  display: flex;
  align-items: stretch;
}
