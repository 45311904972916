@import "#/mixins.scss";

.page {
  @include display-flex-row;
  $page-padding: 24px;
  height: 100%;
  padding: $page-padding;
  $p: 20px;

  .search-header {
    margin-bottom: 12px;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;

    .search-title {
      padding: $p;
      padding-bottom: 5px;
    }

    .total-items-found {
      padding: 0 $p;
      color: var(--subtext-color);
      font-size: 0.8rem;
      text-align: left;
    }
  }

  .left-block {
    flex: 1 1 100%;
    @include display-flex-column;
    margin-right: $p;
    height: 100%;
  }

  .right-block {
    flex: 0 0 300px;
    @include display-flex-column;
    height: 100%;
  }

  .loader-container {
    @include display-flex-column;
    @include flex-full-align;
    flex: 1 1 150px;
  }

  .items-container {
    padding: 20px $p 0 $p;
    flex: 1 1 100%;
    @include display-flex-column;

    .search-item {
      $p: 14px;
      @include display-flex-row;
      padding-bottom: $p;
      margin-bottom: $p;
      border-bottom: 1px solid #eee;
      min-height: 80px;

      @mixin item-name-font {
        font-size: 1.1rem;
        a {
          color: var(--black-color) !important;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &.task-search-item {
        .task-search-item__left {
          @include display-flex-column;
          flex: 1 1 100%;

          .task-search-item__tags {
            font-size: 0.8rem;
            margin-top: 12px;
          }
        }

        .task-search-item__right {
          @include display-flex-column;
          flex: 0 0 auto;
          justify-content: flex-start;
          align-items: flex-end;
        }

        .task-search-item__date-info {
          color: var(--subtext-color);
          font-size: 0.8rem;
        }

        .task-search-item__name-and-executor-container {
          @include display-flex-row;
          align-items: center;

          &__sep {
            margin: 0 4px;
          }
        }

        .task-search-item__name {
          @include item-name-font;
        }

        .task-search-item__status {
          font-size: 0.8rem;
          font-weight: bold;
          flex: 0 0 auto;
          color: var(--black-color);
        }

        .task-search-item__executor {
          font-size: 0.8rem;
        }

        .task-search-item__description {
          font-size: 0.8rem;
          $lh: 1.35rem;

          :global(.html-text) {
            line-height: $lh;
            max-height: $lh * 3;
            overflow: hidden;
          }

          &_shown {
            :global(.html-text) {
              max-height: max-content;
            }
          }

          .task-search-item-description-collapse-button {
            display: none;
            font-size: 0.8rem;
            padding: 0;
            color: var(--subtext-color);

            &_shown {
              display: block;
            }
          }
        }
      }

      &.user-search-item {
        .user-search-item__avatar {
          margin-right: 12px;
        }

        .user-search-item__left {
          @include display-flex-column;
        }

        .user-search-item__left-upper {
          @include display-flex-row;
        }

        .user-search-item__name {
          @include display-flex-row;
          align-items: center;
          @include item-name-font;
        }

        .user-search-item__info {
          @include display-flex-column;
        }

        .user-search-item__email {
          font-size: 0.85rem;
          color: var(--subtext-color);
        }

        .user-search-item__roles {
          font-size: 0.65rem;
          color: var(--white-color);
          margin-top: 12px;

          .user-search-item__role-item {
            margin-right: 6px;
            border-radius: 16px;
            background: var(--black-color);
            padding: 2px 6px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &.project-search-item {
        .project-search-item__left {
          @include display-flex-column;
          flex: 1 1 100%;
          margin-right: 12px;
        }

        .project-search-item__right {
          align-items: flex-end;
          @include display-flex-column;
          flex: 1 1 auto;
          justify-content: space-between;
        }

        .project-search-item__name {
          @include item-name-font;
        }

        .project-search-item__date-info {
          @include display-flex-column;
          color: var(--subtext-color);
          font-size: 0.8rem;
        }

        .project-search-item__creator {
          white-space: nowrap;
          a {
            color: var(--black-color);
          }
          font-size: 0.8rem;
        }

        .project-search-item__description {
          color: var(--subtext-color);
          font-size: 0.8rem;
          text-align: left;
          flex: 1 1 100%;
        }
      }
    }
  }

  .search-kinds-block {
    @include display-flex-column;
    flex: 0 0 auto;

    &__item {
      color: var(--primary-color);
      text-align: left;
      border-radius: 0;
      padding: 8px 0;
      padding-left: 20px;
      font-size: 0.91rem;

      &_active {
        color: var(--black-color);
        background: var(--primary-hover-color);
        border-left: 2px solid var(--primary-color);
        font-weight: bold;
      }

      &:hover {
        background: var(--primary-hover-color);
      }
    }
  }
}
