@import "#/mixins.scss";

.copy-input {
  position: relative;
  @include display-flex-row;

  &__textarea {
    resize: none;
  }

  &__button {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    @include box(32px);
    font-size: 0.8rem;

    &:active {
      cursor: default;
    }
  }
}
