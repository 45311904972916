.changelog-headline {
  padding-left: 40px;
  padding-top: 10px;
  font-weight: 300;
  font-size: 1rem;
  color: #673ab7;
}
.changelog-item {
  list-style-type: disc;
  color: #662072;
  font-size: 0.95rem;
}
