$btn-info-color: #0d3fa9;

p .label {
  font-size: 90%;
}

.align-self-center {
  align-self: center;
}

.btn-info-outline {
  background: transparent;
  transition: 0.3s;
  color: $btn-info-color;

  &:hover {
    border: 1px solid $btn-info-color;
  }
}

.task-field-label {
  color: #1a7388;
}

.chat {
  span.k-badge {
    width: 100%;
    height: 45px;
    min-width: 45px;
    max-width: 45px;
    border: 2px solid #fff;
    border-radius: 100%;
    margin-right: 15px;
    box-shadow: 0 5px 10px 4px #f1f1f199;
    display: flex;
  }

  .chat-content {
    max-height: 370px;
    overflow-y: scroll;
    padding-right: 1.25rem;
  }

  .bottom {
    display: flex;
    align-items: center;
    padding-top: 30px;
    border-top: 2px solid #fbfbfb;

    .form-control {
      padding: 16px 20px;
      min-height: 56px;
      padding-right: 50px;
      resize: none;
      overflow: hidden;
      font-weight: 400;
      background: #f5f5f5;
      width: 100% !important;
      border-radius: 6px;
      border: none;
      font-size: 1rem;
      color: #878093 !important;

      &::placeholder {
        font-size: 1rem;
        font-weight: 400;
        color: #bdbac2;
      }
    }

    .btn.send {
      padding: 0 20px 0 20px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      line-height: 0;
      color: #bdbac2 !important;
      background: transparent;

      i {
        font-size: 1.5rem;
      }
    }
  }
}
