body {
  min-height: 100vh;
  display: flex;
}

#application_root {
  width: 100%;
}

.login {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  height: 100%;

  .login-root {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex: 1;
    -ms-flex: 1 0 0px;
  }

  .login-root-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    background: #fff;
    max-width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }

  .login-root-form {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 700px;
    padding: 2rem 8rem;
    max-width: 100%;
    align-self: center;

    @media screen and (min-width: 1025px) and (max-width: 1200px) {
      width: 400px;
      margin: 0 auto;
      padding: 3rem 3rem;
    }

    .login-root-form-inner {
      width: 100%;
      height: 100%;
      display: flex;

      flex-direction: column;
    }

    .login-item-fluid {
      height: auto;
    }

    .login-wrapper {
      overflow: hidden;
      padding: 0 2rem;
    }

    .login-logo {
      text-align: center;
      margin: 0 auto 4rem auto;

      img {
        height: 100px;
        border-radius: 50%;
      }
    }

    .login-sign-in {
      display: block;

      .login-head {
        margin-top: 1rem;

        h3 {
          text-align: center;
          font-size: 1.5rem;
        }
      }

      form {
        margin-top: 3rem;

        .form-group {
          margin: 0;
          padding: 0;
          position: relative;

          input.form-control {
            border-radius: 0;
            border: 0;
            border-bottom: 1px solid #ebedf2;
            padding: 1rem 0;
            margin-top: 0.1rem;
            color: #575962;

            &.login-input-last {
              border: 0;
            }

            &:focus::placeholder {
              color: #716aca;
              opacity: 1;
            }
          }
        }

        .login-form-sub {
          margin-top: 2rem;
        }
      }
    }

    .login-form-action {
      margin: 3rem 0 3rem 0;
      text-align: center;

      button {
        display: inline-block;
        text-align: center;
        padding: 1rem 3rem;
        color: #fff;
        -webkit-box-shadow: 0px 5px 10px 2px rgba(152, 22, 244, 0.19) !important;
        box-shadow: 0px 5px 10px 2px rgba(152, 22, 244, 0.19) !important;
        font-size: 1rem;
        font-weight: 400;
        color: #fff;
        background-color: #9816f4;
        border-color: #9816f4;

        &.loading {
          background-color: unset;
          border-color: unset;
        }

        &:hover,
        &:active {
          background-color: #840ad9;
          border-color: #7c0acd;
          -webkit-box-shadow: 0px 5px 10px 2px rgba(152, 22, 244, 0.36) !important;
          box-shadow: 0px 5px 10px 2px rgba(152, 22, 244, 0.36) !important;
        }
      }
    }
  }

  .login-root-image {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../../assets/login-img.jpeg);
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
    background-attachment: fixed;
    background-size: cover;
    max-width: 100%;
    align-content: center;

    .login-root-image-inner {
      max-width: 100%;
      flex: none;
      padding: 4.5rem;
    }

    .login-image-welcome {
      color: #fff;
      font-size: 3.5rem;
      font-weight: 500;
      margin-bottom: 2rem;

      @media screen and (min-width: 1025px) and (max-width: 1200px) {
        font-size: 1.5rem;
      }
    }

    .login-image-msg {
      color: #fff;
      font-size: 1.1rem;
      font-weight: 200;
    }
  }

  .login-item-center {
    text-align: center;
    display: table-row;
    vertical-align: top;
    height: 1px;

    .login-account-msg {
      font-size: 0.95rem;
      font-weight: 400;
      color: #898b96;
    }
  }

  .login-error {
    opacity: 0;
    visibility: hidden;
    transition: 0.25s;

    &.active {
      opacity: 1;
      visibility: visible;
      transition: 0.25s;
    }
  }
}

@media screen and (max-width: 1024px) {
  .login .login-root-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .login .login-root-image {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    order: 1;
    -webkit-box-ordinal-group: 2;
  }

  .login .login-root-form {
    order: 2;
    -webkit-box-ordinal-group: 3;
    width: 500px;
    margin: 0 auto;
    padding: 11rem 1rem;
  }

  .login .login-root-image .login-image-welcome {
    font-size: 1.5rem;

    margin-bottom: 1rem;
  }

  .login .login-root-image .login-image-msg {
    font-size: 1rem;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .login .login-root-form {
    width: 100%;
    padding: 11rem 2rem;
  }
}

.login-link {
  transition: 0.25s;
  font-size: 1rem;
  color: #716aca;
  text-decoration: none;
  position: relative;
  display: inline-block;
  outline: none;

  &::after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0%;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }

  &:hover {
    text-decoration: none;
  }
}
