@import "#/mixins.scss";

.root {
  @include display-flex-column;
  @include box(100%);
  @include flex-full-align;
  flex: 1;
  background: white;
}

.message {
  max-width: 500px;
  padding: 24px;

  &__title {
    margin-bottom: 12px;
    h2 {
      margin-bottom: 0;
    }
  }

  &__text {
    margin-bottom: 12px;
    font-size: 0.95rem;
  }

  &__button {
    @include display-flex-row;

    &__button {
      margin-right: 8px;
    }
  }
}
