.profile-user-img {
  margin: 15px auto;
  width: 180px;
  height: 180px;
  box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2),
    0 1px 5px 0 rgba(169, 169, 169, 0.12);

  &__editable {
    opacity: 1;
  }
}
.profile-username {
  font-size: 1.3125rem;
  margin-top: 5px;
}

.box-profile {
  label {
    font-size: 0.9rem;
  }
  .social-states {
    font-size: 1.5rem;
  }
}

.profile-user-info {
  padding: 30px 0 0 0;
  border-top: 1px solid #ccc;
  margin-top: 30px;

  .profile-info-block {
    margin-top: 10px;

    &-info {
      span {
        font-weight: 500;
        font-size: 0.9rem;
      }
      div {
        margin-top: 5px;
        font-size: 0.9rem;
      }
    }

    &-email,
    &-discord,
    &-joined {
      span {
        font-weight: 500;
        font-size: 0.8rem;
      }

      div {
        font-size: 0.9rem;
      }
    }

    &-status-modification {
      margin-top: 15px;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
.profile-user-info p {
  color: #67757c;
  font-size: 0.9rem;
  margin-bottom: 0.65rem;
}

.profile-edit-btn {
  position: absolute;
  right: 15px;
}

.profile-tasks-box {
  margin-bottom: 20px;
  background: #fff;
  -webkit-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
