@import "#/mixins.scss";

.root {
  @include display-flex-column;
  height: 100%;
}

.row {
  height: 100%;
}

.content {
  height: 100%;
}

.loader-wrapper {
  @include display-flex-column;
  @include flex-full-align;
  height: 100%;
}

.status-change-button {
  width: 100%;
  font-size: 0.8rem;
}

.user-info-block-wrapper {
  height: 100%;
  &__box {
    height: 100%;
  }
}
