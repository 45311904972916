@import "#/mixins.scss";

.user-projects-widget {
  &__header {
    @include display-flex-row;
    margin-bottom: 6px;
    color: #67757c;
    font-weight: 500;
    font-size: 0.8rem;
  }

  .user-projects-title {
    flex: 0 0 auto;
    margin-right: 4px;
  }

  .user-projects-actions {
    flex: 0 0 auto;
    @include display-flex-row;

    .user-projects-action-button {
      flex: 0 1 auto;
      outline: none;
      border: none;
      background: rgba(0, 0, 0, 0);
      cursor: pointer;
      padding: 0;
      margin-left: 14px;
      color: inherit;
      font-size: inherit;

      &:hover {
        color: grey;
      }

      &:active {
        cursor: default;
      }
    }
  }

  .user-projects-count {
    flex: 0 1 auto;
  }

  .user-projects-minimized-list {
    @include display-flex-row;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;

    &__item {
      font-size: 0.85rem;

      &::after {
        content: ",";
        color: grey;
        display: inline-block;
        margin-right: 0.3rem;
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }
}

.multiselect {
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 0;
  overflow: visible;

  .multiselect-items-container {
    // border-bottom: 1px solid #eee;
    // padding-bottom: 8px;
  }

  .multiselect-item {
    @include disable-user-select;
    @include display-flex-row;

    padding: 6px 0;
    font-size: 0.8rem;

    &__name {
      flex: 1 1 100%;
    }

    &__remove-button {
      background: rgba(0, 0, 0, 0);
      border: none;
      outline: none;
      cursor: pointer;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: 0px;
      padding: 0;
      font-size: inherit;
      color: var(--dark);
    }
  }

  .dropdown-container {
    @include display-flex-row;
    justify-content: flex-end;
    padding: 8px 0;
  }

  &__no-items-message {
    @include display-flex-row;
    @include flex-full-align;
    padding: 16px 0;
  }

  &__show-item-select-button {
    $bg: rgba(0, 0, 0, 0);
    background: $bg;
    color: #000;
    border: none;
    outline: none;
    padding: 0;
    font-size: 0.9rem;

    &__text {
      margin-right: 8px;
    }

    &:hover {
      background: $bg;
      color: #444;
    }

    &:focus {
      background: $bg;
    }

    &:active {
      background: $bg;
    }
  }
}
