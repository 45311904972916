@import "#/mixins.scss";

.dropdown-container {
  .dropdown {
    &.dropdown_shown {
      .dropdown-menu {
        max-height: 250px;
        overflow-y: auto;
        // transform: scale(1) translate3d(30px, 0, 0px) !important;
        // transform-origin: left 0 !important;
        transform: scale(1);
      }
    }

    &:hover .treeview-link {
      overflow: visible;
    }

    .dropdown-menu {
      padding: 0.5rem 0;
      transition: transform 0.2s;
      transform-origin: top center;
      display: block;
      overflow: hidden;
      max-height: 250px;
      // transform: scale(0) translate3d(0px, 38px, 0px);
      transform: scale(0);
      // transform-origin: left 0;
    }

    .search-container {
      padding: 0 0.5rem;
      margin-bottom: 0.25rem;

      .search-input {
        font-size: 0.85rem;
        box-sizing: border-box;
      }
    }

    .items-container {
      padding: 0.25rem;

      .dropdown-item {
        font-size: 0.82rem;
        padding: 0 0.5rem;
        margin: 0;
        // margin-bottom: 0.15rem;
        height: 30px;
        cursor: pointer;

        &:active {
        }
      }

      .no-items-message {
        @include display-flex-row;
        @include flex-full-align;
        font-size: 0.8rem;
        padding: 0.8rem 0;
      }
    }
  }
}
