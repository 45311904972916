.excel-icon {
  font-size: 1.125rem;
}

.table-group-queries {
  tbody td,
  thead th {
    font-size: 0.8rem;
    padding: 0.25rem 0.5rem;
  }
}

.minimum-column {
  width: 1px;
}
