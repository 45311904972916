@import "#/mixins.scss";

.table-filters-container {
  .user-self-assigned-task-list-hidden-group {
    @include display-flex-row;
    justify-content: flex-end;

    &__label {
      margin-right: 6px;
      font-size: 0.9rem;
    }
  }
}
