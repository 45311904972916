@import "#/mixins.scss";

$hpadding: 20px;

.notification-menu {
  .notification-menu-button {
    position: relative;
    &__status-circle {
      background: rgb(190, 22, 22);
      border-radius: 50%;
      @include box(20px);
      cursor: pointer;
      color: #fff;
      font-size: 0.75rem;
      position: absolute;
      right: 0;
      top: 5px;
      @include display-flex-column;
      @include flex-full-align;
    }
  }

  .notification-menu-dropdown {
    width: var(--notification-menu-dropdown-width) !important;
    max-width: var(--notification-menu-dropdown-width) !important;
    height: var(--notification-menu-dropdown-height) !important;
    max-height: var(--notification-menu-dropdown-height) !important;
    overflow-x: hidden !important;
    padding: $hpadding 0;
    cursor: default;
    @include display-flex-column;

    &__items-container {
      overflow: hidden;
      overflow-y: scroll;
      flex: 1 1 100%;
    }

    &__loader-container {
      @include display-flex-row;
      @include flex-full-align;
      flex: 1 1 auto;
    }

    &__no-items-message {
      @include display-flex-column;
      @include flex-full-align;
      width: 100%;
      font-size: 0.95rem;
      margin: auto 0;
      flex: 1 1 100%;
    }

    .notification-menu-header {
      @include display-flex-row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;
      flex: 0 0 var(--notification-menu-header-height);
    }
  }
}

.tabs-container {
  @include display-flex-row;

  .tab {
    border-radius: 4px;
    cursor: pointer;
    padding: 6px 12px;
    margin-right: 4px;
    font-size: 0.9rem;

    &:last-child {
      margin-right: 0;
    }

    &:hover:not(.tab_active) {
      background: var(--primary-hover-color);
    }

    &_active {
      background: var(--primary-color);
      color: white;
    }
  }
}
