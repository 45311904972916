@import "#/mixins.scss";

.loader-container {
  @include display-flex-column;
  @include flex-full-align;
  height: 100%;
}

.project-users {
  .project-users-listing {
    padding: 12px 0;
    .no-users-in-proj-message {
    }
  }

  .users-bottom-row {
    @include display-flex-row;
    width: 100%;
    justify-content: flex-end;
    margin-top: 12px;
  }
}
