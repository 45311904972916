@import "#/mixins.scss";

.column-head-button {
  cursor: pointer;
  text-align: left;
  @include disable-user-select;

  .column-head-content {
    margin-right: 4px;
  }

  &_priority {
    text-align: center;
  }
}

.row-action-column {
  text-align: right !important;
}

.task-expand-row {
  text-align: left !important;
}

.priority-column-cell {
  text-align: center !important;
}
