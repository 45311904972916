@import "#/mixins.scss";

$aw: 45px;

.chat-messages-container {
}

.message {
  @include display-flex-row;
  align-items: flex-end;
  margin-bottom: 20px;
  position: relative;

  &.me {
    // justify-content: flex-end;
  }

  span {
    display: block;
    font-size: 0.9rem;
    font-weight: 400;
  }

  .text-main {
    margin-left: $aw + 15px;

    .text-group {
      width: 100%;
      margin-bottom: 10px;

      // &.me {
      //   display: flex;
      //   justify-content: flex-end;
      // }

      .text {
        display: inline-block;
        padding: 15px;
        max-width: 450px;
        background: #f5f5f5;
        border-radius: 6px;

        &.me {
          background: #2196f3;
          transition: all 0.3s;

          p {
            color: #fff;
          }
        }

        p {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.4;
          margin-bottom: 0;
        }
      }
    }
  }

  &__avatar {
    @include box($aw);
    position: absolute;
    top: 0;
    left: 0;
  }
}
