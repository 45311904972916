.topf-query-positions {
  a {
    color: #455a64;
    padding-left: 4px;
    transition: color 0.25s;
    font-size: 0.8rem;

    &,
    &:hover {
      text-decoration: none;
    }
  }

  .query-position-active {
    box-shadow: 0 0 4px 0 #4caf50;
    color: #4caf50;
    transition: 0.2s;
  }

  .query-position-friendly {
    box-shadow: 0 0 4px 0 #fa4e05;
    color: #fa4e05;
    transition: 0.2s;
  }

  span.date {
    font-weight: 500;
    padding-left: 4px;
    font-size: 0.85rem;
  }

  .query-positions-unique {
    border: 2px solid #dadada;
    // border-bottom: 2px solid #dadada;
    padding: 4px;
    padding-top: 8px;
  }
}

.text-bold {
  font-weight: 500;
}
