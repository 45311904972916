@import "#/mixins.scss";

// CLEANUP: Remove all prefixed properties from the SASS code

.skin-blue {
  @include display-flex-column;
  height: 100%;

  .content-header {
    background: #ffffff;
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
  }

  .content {
    background: #f4f6f9;
  }
}

ul {
  padding-inline-start: unset;
  list-style-position: inside;
}

.bg-sidebar {
  @include display-flex-column;
  height: 100%;
  background: #242a33;
}

.modal-content.alert.alert-primary {
  color: #001d3c;
}

.table tbody tr {
  word-break: break-word;
}

$progress-color: white;

#nprogress .bar {
  background: $progress-color;
}

.table td,
.table th {
  padding: 0.55rem;
}

#nprogress .peg {
  box-shadow: 0 0 10px $progress-color, 0 0 5px $progress-color;
}

#nprogress .spinner-icon {
  border-top-color: $progress-color;
  border-left-color: $progress-color;
}

h1 {
  line-height: 40px;
  font-size: 2.25rem;
}

.btn-white {
  background-color: #fff;
  color: #212529;
}

.content-header {
  position: relative;
  padding: 10px 20px 10px;

  h1,
  .container > h1 {
    margin: 0 0 0px;
    font-size: 1.5rem;
    color: #455a64;
  }

  h1 > small,
  .container > h1 > small {
    font-size: 0.95rem;
    display: inline-block;
    padding-left: 4px;
    font-weight: 300;
  }

  .breadcrumb,
  .container > .breadcrumb {
    float: right;
    background: 0 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.8rem;
    padding: 7px 5px;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 2px;

    &.breadcrumb-big {
      font-size: 0.9rem;
      padding: 4px 5px;
    }
  }

  .breadcrumb > li > a,
  .container > .breadcrumb > li > a {
    color: #455a64;
    text-decoration: none;
    display: inline-block;
  }
}

.content {
  min-height: 250px;
  padding: 20px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 576px) {
    padding: 0;
  }
}

.opacity-none {
  opacity: 0;
}

.box {
  position: relative;
  border-top: 0;
  margin-bottom: 20px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  padding: 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;

  .box-header {
    // display: flex;
    // max-height: 60px;

    .box-subtitle {
      font-weight: 300;
      margin-bottom: 0px;
      margin-top: 5px;
      color: #637076;
    }
  }

  &.bg-dark {
    background-color: #465161 !important;
    color: #fff;
  }

  &.bg-white {
    background-color: #ffffff !important;
    color: rgb(103, 117, 124);
  }
}

.box-inactive .progress .progress-bar {
  background: #acb6ba !important;
}

.box.box-portlet {
  box-shadow: 0px 0px 13px 0px #ebebebcc;
  height: calc(100% - 20px);
  border-radius: 4px;

  .box-header {
    padding: 1.15rem 1.25rem;
  }
}

.border-light {
  border-color: #f1f2f3;
}

.box-body {
  padding: 1.25rem;
  border-radius: 0 0 0px 0px;
  flex: 1 1 auto;
  transition: 0.3s;
}

.flexbox {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.box-header {
  color: #455a64;
  display: block;
  padding: 0.8rem 1.25rem;
  position: relative;
  border-bottom: 1px solid rgba(97, 106, 120, 0.07);

  .box-title,
  .fa,
  .ion {
    display: inline-block;
    margin: 0;
  }
}

.box-header.with-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.box-title {
  z-index: auto;
}

.box-body {
  padding: 1.25rem;
  border-radius: 0 0 0px 0px;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;

  &:last-child {
    margin-bottom: 0;
  }
}

// --- MASK ANIMATION ---

.mask-leave,
.mask-top-leave,
.mask1-leave {
  opacity: 0.8;
}

.mask-leave.mask-leave-active,
.mask-top-leave.mask-top-leave-active,
.mask1-leave.mask1-leave-active {
  opacity: 0;
  transition: all 300ms ease-in;
}

.mask-top-appear {
  transform: translateY(-75%);
  opacity: 0;
  transition: transform 0s ease-in;

  &.mask-top-appear-active {
    opacity: 1;
    transition: opacity 0.3s ease-in, transform 0.3s ease-out;
    transform: translateY(0);
  }
}

.mask-appear {
  // transform: translateY(-10%);
  transform: translateX(-75%);
  opacity: 0;
  transition: transform 0s ease-in;
}

.mask-appear.mask-appear-active {
  opacity: 1;
  transition: opacity 0.3s ease-in, transform 0.3s ease-out;
  transform: translateX(0);
}

// another direction
.mask1-appear table {
  overflow: hidden;
}

.mask1-appear {
  // transform: translateY(-10%);
  transform: translateX(75%);
  opacity: 0;
  transition: transform 0s ease-in;

  &.mask1-appear-active {
    opacity: 1;
    transition: opacity 0.3s ease-in, transform 0.3s ease-out;
    transform: translateX(0);
  }
}

// --- END MASK ANIMATION ---
.overflow-hidden {
  overflow: hidden;
}

.profile-img {
  width: 35px;
  margin-left: 5px;
}

.login-box-body,
.profile-user-img,
.register-box-body {
  box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2),
    0 1px 5px 0 rgba(169, 169, 169, 0.12);
}

.margin-bottom {
  margin-bottom: 20px;
}

.margin-bottom-1 {
  margin-bottom: 10px;
}

.user-image-s {
  max-height: 30px;
  margin-top: 0px;
}

table input.form-control {
  font-size: 0.9rem;
  height: calc(1.75rem + 2px);
}

.flex-vertically-centered {
  display: flex;
  align-items: center;
  align-self: center;
}

.flex-vertically-centered-x {
  display: flex;
  align-items: center;
}

.popover-border-top {
  border-top: 1px solid #eee;
}

.card-body.card-body-checklist {
  padding-top: 2px;
  padding-bottom: 2px;

  &:first-child {
    padding-top: 10px;
  }

  &:last-child {
    padding-bottom: 10px;
  }
}

.preloader-wrapper {
  opacity: 0;
  pointer-events: none;
  opacity: 0;
  pointer-events: none;
  display: none;
  min-height: 0;
  min-width: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.html-text p {
  margin-bottom: 0.05rem;
}

.checklist-box {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: -moz-max-content;
  flex-direction: column;
  align-content: center;
  align-self: center;
}

td img.proj-img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 3px;
}

td a,
td a:hover {
  text-decoration: none;
}

.ml-col-fix {
  margin-left: -15px;
}

.mr-col-fix {
  margin-right: -15px;
}

.wordstat-style {
  font-style: italic;
  font-size: 0.8rem !important;
}

table tr {
  td.column-with-input,
  th.column-with-input {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    input,
    input.form-control {
      width: unset;
      height: 1.4rem;
      cursor: text;
    }
  }
}
table input.form-control {
  &.form-control-small {
    width: unset;
    font-size: 0.85rem;
  }
  &.form-control-small-height {
    height: calc(1.5rem + 2px);
  }
}

.d-flex-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.bb-1 {
  border-bottom: 1px solid #dedede;
  padding-bottom: 6px;
}

.small-3 {
  font-size: 70%;
}

.table-striped tbody,
.table-striped-custom {
  tr:nth-child(even),
  tr:nth-child(even) td {
    background-color: #f8f8ff;
  }
}

.text-muted-3 {
  color: #b3b6b9 !important;
}

@media screen and (min-width: 1600px) {
  .column-wide-screen-small {
    width: 100px;
  }
}

.copyable-text {
  color: #3f51b5;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    color: #8fc8fa;
  }
}

a.link-black {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.position-relative {
  position: relative;
}
