// CLEANUP: Move this file to #/styles
@import "#/mixins.scss";

:root {
  // Colors
  --primary-color: #0070ff;
  --secondary-color: #616a72;
  --primary-hover-color: #eef0f3;
  --primary-active-color: #b6beca;
  --success-color: #00c292;
  --warning-color: #fec107;
  --brown-color: #2f3d4a;
  --dark-grey-color: #3f6b80;
  --orange-color: #ff9800;
  --danger-color: #ef5350;
  --info-color: #03a9f3;
  --subtext-color: #5a5a5a;
  --black-color: #222;
  --white-color: #fff;

  // Notification menu
  --notification-menu-dropdown-width: 500px;
  --notification-menu-dropdown-height: 400px;
  --notification-menu-item-min-height: 60px;
  --notification-menu-header-height: 50px;

  // General navbar
  --navbar-sides-padding: 0.5rem;
  --navbar-button-hover-bg: #1b3e80;
}

@font-face {
  font-family: sfprodisplay;
  src: url("./assets/sfprodisplay/SFProDisplay-Regular.woff");
  font-weight: 400;
}

@font-face {
  font-family: sfprodisplay;
  src: url("./assets/sfprodisplay/SFProDisplay-Medium.woff");
  font-weight: 500;
}

@font-face {
  font-family: sfprodisplay;
  src: url("./assets/sfprodisplay/SFProDisplay-Semibold.woff");
  font-weight: 600;
}

@font-face {
  font-family: sfprodisplay;
  src: url("./assets/sfprodisplay/SFProDisplay-Bold.woff");
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
  font-family: "sfprodisplay", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #f4f6f9;
  background-color: #f4f6f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-purple {
  background: #673ab7;
}

h6 {
  line-height: 16px;
  font-size: 0.9rem;
  font-weight: 400;
}

.component-loading {
  opacity: 0.8;
  transition: 0.2s;
}

.overflow-visible {
  overflow: visible;
}

.wrapper {
  transition: 0.2s;
  @include display-flex-column;
  height: 100%;
}

.icon-cyan {
  color: #37b6c4;
}

h4 {
  line-height: 22px;
  font-size: 1.125rem;
}

input:not(output):-moz-ui-invalid {
  box-shadow: unset;
}

.form-control:focus {
  border-color: #7ebcd6;
  outline: 0;
  box-shadow: none;
}

.font-size-18,
.fsz-18 {
  font-size: 1.286rem !important;
}

.fsz-16 {
  font-size: 1.186rem !important;
}

.Toastify__toast-container {
  width: 384px;

  .Toastify__toast {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    font-family: "Rubik", sans-serif;
  }

  .toast-red {
    padding: 16px 24px;
    border-left: 8px solid rgb(151, 6, 6);
    background: #b72d1f;

    .Toastify__progress-bar {
      height: 2px;
      background-color: rgb(144, 19, 10);
    }
  }
  .toast-green {
    padding: 16px 24px;
    background-color: #4ec653;
    border-left: 8px solid #40b656;

    .Toastify__progress-bar {
      height: 2px;
      background-color: rgb(90, 218, 95);
    }
  }
  .toast-primary {
    padding: 16px 24px;
    background-color: #39cccc;
    border-left: 8px solid #2aa2a2;

    .Toastify__progress-bar {
      height: 2px;
      background-color: rgb(102, 217, 217);
    }
  }

  .toast-title {
    font-size: 1rem;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .toast-message {
    font-size: 0.9rem;
  }
}
.font-size-40 {
  font-size: 2.857rem !important;
  line-height: 1.2;
}
.text-primary {
  color: #ab8ce4 !important;
}
.no-front-margins {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.no-front-paddings {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.progress-xxs,
.progress.xxs {
  height: 3px;
}
.progress-sm {
  height: 10px;
}
.progress-bar-warning,
.progress-bar-yellow {
  background-color: #fec107;
}
.progress-bar-aqua,
.progress-bar-info {
  background-color: #03a9f3;
}
.progress-bar-danger,
.progress-bar-red {
  background-color: #ef5350;
}
.cursor-pointer {
  cursor: pointer;
}
.badge {
  border-radius: 3px;
  font-weight: 300;
  line-height: 1.3;
  font-size: 85%;
}
.badge-success {
  background-color: #00c292;
}
.badge-default {
  color: #788394;
  background-color: #f5f6f7;
}

.br-1 {
  border-right: 1px solid #ebebeb !important;
}
.text-info {
  color: #03a9f3 !important;
}

.bg-info {
  background-color: #03a9f3 !important;
  color: #fff;
}
.text-warning {
  color: #faa64b !important;
}
.bg-warning {
  background-color: #fec107 !important;
  color: #fff;
}
.text-danger {
  color: #fb9678 !important;
}
.bg-danger {
  background-color: #fb9678 !important;
  color: #fff;
}
.text-thin {
  font-weight: 300;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-danger {
  background-color: #fb9678;
  border-color: #fb9678;
  color: #fff;
  &:not(:disabled):hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
}

.btn-warning {
  &:not(:disabled):hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }
}

.btn-info {
  background-color: #03a9f3;
  border-color: #03a9f3;
  color: #fff;
  &:not(:disabled):hover {
    background-color: #65bdf8;
    border-color: #65bdf8;
    color: #fff;
  }
}

.btn-success {
  background-color: #00c292;
  border-color: #00c292;
  color: #fff;
  &:not(:disabled):hover {
    background-color: #16d17f;
    border-color: #16d17f;
    color: #fff;
  }
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

input.form-control {
  &:focus {
    box-shadow: none;
  }
}

.modal-content {
  border-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -5%);
  transform: translate(0, -5%);
}

.modal.show .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.btn-group-sm > .btn,
.btn-sm {
  font-size: 0.85rem;
  padding: 4px 12px;
  line-height: 20px;
}

.btn-rounded {
  border-radius: 60px;
}

.divider {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  flex: 0 1 0%;
  color: #929daf;
  font-size: 0.8rem;
  letter-spacing: 0.5px;
  margin: 2rem auto;
  width: 100%;
}

.text-black {
  color: #222;
}

.label {
  // display: inline;
  padding: 0.3em 0.6em 0.3em;
  font-size: 78%;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label-success {
  background-color: var(--success-color) !important;
  &:hover,
  &:active,
  &:focus {
    background-color: var(--success-color) !important;
  }
}

.label-warning {
  background-color: var(--warning-color) !important;
  &:hover,
  &:active,
  &:focus {
    background-color: var(--warning-color) !important;
  }
}

.label-inverse {
  background-color: var(--brown-color) !important;
  &:hover,
  &:active,
  &:focus {
    background-color: var(--brown-color) !important;
  }
}

.label-inverse-simple {
  background-color: var(--dark-grey-color) !important;
  &:hover,
  &:active,
  &:focus {
    background-color: var(--dark-grey-color) !important;
  }
}

.label-orange {
  background-color: var(--orange-color) !important;
  &:hover,
  &:active,
  &:focus {
    background-color: var(--orange-color) !important;
  }
}

.label-danger {
  background-color: var(--danger-color) !important;
  &:hover,
  &:active,
  &:focus {
    background-color: var(--danger-color) !important;
  }
}

.label-info {
  background-color: var(--info-color) !important;
  &:hover,
  &:active,
  &:focus {
    background-color: var(--info-color) !important;
  }
}

.label.label-small {
  font-size: 0.85rem;
  padding: 0.35rem 0.5rem;
  vertical-align: middle;
}

td .label {
  font-size: 100%;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .table tbody td,
  .table thead tr th {
    font-size: 0.8rem;
  }

  .label,
  td .label {
    font-size: 0.8rem;
    padding: 0.5em 0.65em;
  }

  .table td[id^="popover-row"] {
    padding: 0;
  }

  .font-size-40 {
    font-size: 1.7rem !important;
    line-height: 1.2;
  }

  .font-size-18 {
    font-size: 1rem !important;
  }

  .selected-task-type .box-body {
    padding: 1rem;
  }
}

i.priority-label {
  font-size: 1.25rem;

  &.fa-exclamation-circle {
    color: red;
  }
}

.accordion .card:first-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
select {
  outline: none;
}

i.high-priority {
  display: inline-block;
  border-radius: 60px;
  // box-shadow: 0px 0px 2px #dc3545;
  color: #dc3545;
}

i.med-priority {
  color: #ffc107;
}

i.low-priority {
  color: #28a745;
}

i[class$="priority"] {
  padding: 0.4em 0.6em;
}

.rc-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.9rem;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 20px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  -webkit-transition: all 0.36s;
  transition: all 0.36s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:after {
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
    position: absolute;
    top: 1px;
    left: 1px;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 18px;
    cursor: pointer;
    -webkit-transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: " ";
  }
  &:focus {
    outline: none;
  }
}
.rc-switch-offset-right {
  margin-right: 10px;
}
.rc-switch-inner {
  color: #fff;
  font-size: 0.8rem;
  position: absolute;
  left: 24px;
  top: 0;

  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: rcSwitchOff;
  animation-name: rcSwitchOff;
}

.rc-switch-checked {
  border: 1px solid #1890ff;
  background-color: #1890ff;

  .rc-switch-inner {
    left: 6px;
  }

  &:after {
    left: 100%;
    margin-left: -1px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
.fz-13 {
  font-size: 0.85rem;
}
.fz-14 {
  font-size: 0.9rem;
}

.fz-16 {
  font-size: 1rem;
}

.label-big {
  font-size: 90%;
}
.vertical-align-middle {
  vertical-align: middle !important;
}

.half-opacity {
  opacity: 0.5;
}

@media screen and (max-width: 991px) {
  .content-wrapper.sidebar-active {
    display: none;
  }
}

.m0-auto {
  margin: 0 auto;
}

#application_root {
  @include display-flex-column;
}

.low-opacity {
  opacity: 0.5;
  pointer-events: none;
}

.flex-spacer {
  flex: 1 1 100%;
}

.flex-1 {
  flex: 1 1 100%;
}

table tr .table-checkbox {
  display: block;
  height: 100%;
}

.no-table-items-message {
  width: 100%;
  text-align: center;
  min-height: 60px;
}

.disable-pointer-events {
  @include disable-pointer-events;
}

.disable-user-select {
  @include disable-user-select;
}
