@import "#/mixins.scss";

.add-checklist-item {
  @include display-flex-column;
  justify-content: center;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
}

.add-multiple-checkbox-container {
  @include display-flex-row;
  margin-top: 12px;
  overflow: hidden;
  position: relative;
  align-items: center;

  &__label {
    margin: 0;
    margin-left: 8px;
  }
}
